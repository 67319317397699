<template>
  <div class="point">
    <div class="titleimg">
      <img src="../../assets/images/home/pointboxtitle.png" alt="" />
    </div>
    <div class="pointboxlist">
      <div
        class="pointbox"
        v-for="item in listdata"
        :key="item.id"
        @click="ToOpen(item.box.id, item.id)"
      >
        <!-- {{ item }} -->
        <div class="pic">
          <img v-lazy="item.box.cover" />
        </div>
        <div class="weapon_cover"></div>
        <div class="Blind_btn">
          <span>{{ item.name }}</span>
        </div>

        <p
          style="
            color: #dea61f;
            font-size: 0.23rem;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <Cpoint :price="item.box.bean" />
        </p>
      </div>
    </div>
    <div class="titleimg" v-if="turntabllist">
      <img src="../../assets/images/home/fuliboxtitle.png" alt="" />
    </div>
    <div class="pointboxlist" v-if="turntabllist">
      <div class="pointbox" @click="tokaijian()">
        <div class="pic">
          <img v-lazy="turntabllist.cover" />
        </div>
        <div class="weapon_cover"></div>
        <div class="Blind_btn">
          <span>福利转盘</span>
        </div>
        <p
          style="
            color: #dea61f;
            font-size: 0.23rem;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <Cpoint :price="turntabllist.score" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getpointData, getturntableApi } from "../../network/api";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      listdata: [],
      turntabllist: [],
    };
  },
  created() {
    this.getdata();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async getdata() {
      const res = await getpointData();
      this.listdata = res.data.data;
      //  转盘接口
      const turntabl = await getturntableApi();
      this.turntabllist = turntabl.data.data;
    },
    //开箱
    ToOpen(id, boxid) {
      // 记录当前滚动位置
      localStorage.setItem("scroll", document.documentElement.scrollTop);

      this.$router.push({
        path: "/OpeningBox",
        query: {
          id: id,
          typeid: boxid,
        },
      });
    },
    // 转盘接口
    tokaijian() {
      if (!this.user.id) {
        this.$message.error("请先登录");
      } else {
        this.$router.push({
          path: "/kaijian",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.point {
  margin-top: 0.45rem;
  padding-bottom: 0.2rem;
}
.titleimg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.02rem;
  img {
    width: 4.2rem;
    height: 100%;
  }
}
.pointboxlist {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;

  .pointbox {
    width: 20%;
    cursor: pointer;
    margin: 0rem 0 0rem;
    position: relative;
    // padding-bottom: 20px;
    .weapon_cover {
      // width: 2rem;
      height: 2.8rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 50%;
        margin-left: 0.3rem;
        margin-top: -0.1rem;
        // max-width: 100%;
        // max-height: 100%;
        transition: 0.3s;
      }
    }

    .pic {
      //
      position: absolute;
      left: 47%;
      top: 50%;
      width: 90%;
      transform: translate(-45%, -57%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;

      img {
        max-width: 100%;
        max-height: 100%;
        transition: 0.3s;
      }
    }

    p {
      height: 20px;
      font-size: 0.18rem;
      line-height: 25px;
      color: #fff;
      text-align: center;
    }

    .Blind_btn {
      width: 100%;
      height: 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 99;

      p {
        display: none;
        width: 50%;
        height: 34px;
        text-align: center;
        line-height: 34px;
        font-size: 0.16rem;
        /*font-weight: 550;*/
        color: #fff;
        position: relative;
        z-index: 99;
      }

      span {
        width: 90%;
        text-align: center;
        font-size: 0.2rem;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        img {
          margin-right: 10px;
          width: 26px;
        }
      }
    }

    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: 100%;
    }

    &:hover {
      &::before {
        top: -0.3rem;
        width: 3.5rem;
        height: 3.5rem;
        animation: zhuan 10s linear infinite;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/OpenBox/pic-bg.png");
      }

      .weapon_cover {
        img {
          transform: rotate(-10deg) scale(1.6);
        }
      }
      .pic {
        img {
          transform: rotate(0deg) scale(1.3);
        }
      }

      .Blind_btn {
        position: relative;
        z-index: 99;

        span {
          display: block;
          color: #fff;
        }

        p {
          display: none;
          color: #000;
        }
      }
    }
  }
}
</style>
